$contact-horiz-padding: 2rem;
$contact-left-padding-lg: 6rem;

main#contact .main-container {
    padding-bottom: 0;
}

.contact-row {
    background-color: $translucent-darker-grey;
    color: white;
    text-transform: uppercase;

    padding: 3rem $contact-horiz-padding 10rem;

    @media (min-width: $screen-lg-min) {
        padding-left: $contact-left-padding-lg;
    }

    &.main-text a,
    .phone {
        color: $green;
    }

    .phone {
        font-size: 1.3em;
    }

    address.email-phone {
        padding-bottom: 2em;
    }

    hr {
        width: 100%;
        text-align: left;
        border: 1px solid white;
        margin-left: -$contact-horiz-padding - 1.5rem;

        @media (min-width: $screen-lg-min) {
            margin-left: -$contact-left-padding-lg - 1.5rem;
        }
    }

    

    table.horaire {
        max-width: 400px;
    }

    .map-container {
        margin-top: 6rem;

        .location-map {
            width: 100%;
            height: 450px;
            border: 10px solid $green;

            @media (min-width: $screen-sm-min) {
                height: 340px;
            }
            @media (min-width: $screen-md-min) {
                height: 425px;
            }
            @media (min-width: $screen-lg-min) {
                height: 500px;
            }
        }
    }
}