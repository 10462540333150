$showcase-photo-padding: 30px;
$showcase-photo-height-xs: 200px;
$showcase-photo-height-sm: $container-sm /2;
$showcase-photo-height-md: $container-md /2;
$showcase-photo-height-lg: $container-lg /2;
$showcase-caption-height: 30px;

$slider-height: 95px;
$slider-arrow-width: 50px;


.photo-showcase-container {
    background-color: white;
    padding: $showcase-photo-padding $slider-arrow-width;
}

.photo-showcase {
    @extend .showcase-height;

    margin-bottom: 0;
    background-color: white;
    padding: 0 $showcase-photo-padding;

    .showcase-slide.slick-slide {
        @extend .showcase-height;

        img {
            position: absolute;
            @extend .showcase-img-top;
            left: 50%;
            transform: translate(-50%, -50%);
            @extend .showcase-photo-max-height;
            max-width: 100%;
        }

        .showcase-caption {
            position: absolute;
            bottom: $showcase-photo-padding / 2;
            left: 50%;
            transform: translate(-50%, 50%);
        }
    } 
}

@mixin showcase-height($height) {
    height: $height + $showcase-photo-padding + $showcase-caption-height + 5px;
}

@mixin showcase-img-top($height) {
    top: ($height + $showcase-photo-padding + 5px) / 2;
}

.showcase-height {
    @include showcase-height($showcase-photo-height-xs);

    @media(min-width: $screen-sm-min) {
        @include showcase-height($showcase-photo-height-sm);
    }
    @media(min-width: $screen-md-min) {
        @include showcase-height($showcase-photo-height-md);
    }
    @media(min-width: $screen-lg-min) {
        @include showcase-height($showcase-photo-height-lg);
    }
}

.showcase-img-top {
    @include showcase-img-top($showcase-photo-height-xs);

    @media(min-width: $screen-sm-min) {
        @include showcase-img-top($showcase-photo-height-sm);
    }
    @media(min-width: $screen-md-min) {
        @include showcase-img-top($showcase-photo-height-md);
    }
    @media(min-width: $screen-lg-min) {
        @include showcase-img-top($showcase-photo-height-lg);
    }
}

.showcase-photo-max-height {
    max-height: $showcase-photo-height-xs;

    @media(min-width: $screen-sm-min) {
        max-height: $showcase-photo-height-sm;
    }
    @media(min-width: $screen-md-min) {
        max-height: $showcase-photo-height-md;
    }
    @media(min-width: $screen-lg-min) {
        max-height: $showcase-photo-height-lg;
    }
}

.photo-slider-container {
    background-color: white;
    padding: 0 $slider-arrow-width $showcase-photo-padding;
}

.photo-slider-col {
    @extend .col-xs-12;
    padding-left: 30px;
    padding-right: 30px;
}

.photo-slider,
.photo-slider img {
    height: $slider-height;
    transition: none;
}

.photo-slider {
    margin-bottom: 0;

    .slider-slide {
        cursor: pointer;

        &:hover, &:focus {
            outline: none;
        }
    }

    img {
        margin: 0 5px;
    }

    img[data-lazy] {
        width: 123px;
    }
}

.photo-slider-play,
.photo-slider-pause {
    color: $light-grey;

    &:hover, &:focus {
        color: $main-grey;
    }
}

.slick-prev,
.slick-next {
    height: 40px;
    width: 40px;

    &:before {
        font-family: FontAwesome;
        font-size: 40px;
        line-height: 1;
        color: $light-grey;
        opacity: 0.75;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &:hover, &:focus {
            color: $main-grey;
        }
    }
}

.slick-prev {
    left: -45px;

    &:before {
        content: "\f053";
    }
}

.slick-next {
    right: -45px;

    &:before {
        content: "\f054";
    }
}

.photo-slider .slick-current img {
    padding: 2px;
    border: 3px solid $green;
    transition: none;
}
