@for $i from 0 through 4 {
    .pt#{$i} { padding-top: 1em * $i; }
    .pr#{$i} { padding-right: 1em * $i; }
    .pb#{$i} { padding-bottom: 1em * $i; }
    .pl#{$i} { padding-left: 1em * $i; }
    .pa#{$i} { padding: 1em * $i; }

    .mt#{$i} { margin-top: 1em * $i; }
    .mr#{$i} { margin-right: 1em * $i; }
    .mb#{$i} { margin-bottom: 1em * $i; }
    .ml#{$i} { margin-left: 1em * $i; }
    .ma#{$i} { margin: 1em * $i; }
    .main-section article .row.mb#{$i} { margin-bottom: 1em * $i; }
}

.pthalf { padding-top: 0.5em; }
.prhalf { padding-right: 0.5em; }
.pbhalf { padding-bottom: 0.5em; }
.plhalf { padding-left: 0.5em; }
.pahalf { padding: 0.5em; }

.mthalf { margin-top: 0.5em; }
.mrhalf { margin-right: 0.5em; }
.mbhalf { margin-bottom: 0.5em; }
.mlhalf { margin-left: 0.5em; }
.mahalf { margin: 0.5em; }

$contact-line-extension-width: 150px;

$screen-xs-min: 400px;
$screen-xxs-max: 399px;

@media (max-width: $screen-xxs-max) {
  @include responsive-invisibility('.hidden-xxs');
}

@media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
  @include responsive-invisibility('.hidden-xs');
}


