.form-container {
    margin: 2em 0;
    padding: 3em;
    border: 5px solid black;
    background-color: $form-background-color;
    @extend .form-font-sizes;

    label, .form-label {
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1.5em;
    }
}

.green-btn,
.transparent-btn {
    @extend .btn;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: bold;
}

.green-btn {
    @extend .btn-success;
    background-color: $green;
    border-color: darken($green, 5%);

    &:hover, &:focus {
        background-color: darken($green, 15%);
        border-color: darken($green, 20%);
    }
}

.transparent-btn {
    @extend .btn-default;
}

.transparent-grey-btn {
    @extend .transparent-btn;
    @extend .color-transition;
    color: $en-tete-grey;
    border-color: $en-tete-grey;
    background-color: transparent;

    &:hover, &:focus {
        background-color: $en-tete-grey;
        color: $index-video-grey;
    }
}

.form-group .checkbox-inline:first-child {
    padding-left: 0;
}

label.required::after {
    content: "*";
}