.member-modal {
    .modal-content {
        border-radius: 0;
        background-color: $member-modal-background-color;

        .row {
            margin: 1rem 0;
        }

        .member-image {
            @extend .img-responsive;
            width: 100%;
        }

        .member-titles {
            .member-name {
                color: $green;
            }

            .member-profession {
                color: white;
                @extend .mb0;
            }
        }

        .member-bio {
            &,
            p {
                color: white;
                @extend .member-bio-fonts;
            }
            

            hr {
                border-color: $green;
            }
        }

        .modal-close-button {
            @media (min-width: $screen-sm-min) {
                position: absolute;
                top: 0;
                right: 1rem;
            }

            a {
                color: inherit;
                text-decoration: none;
                @extend .color-transition;

                &:hover { color: $mobile-menu-button-hover-color; }
                &:active { color: $mobile-menu-button-color; }
            }
        }
    }
}

.video-modal,
.photo-modal {
    .modal-content {
        border-radius: 0;
        background-color: $member-modal-background-color;

        .row {
            margin: 1rem 0;
        }

        .modal-close-button {
            a {
                color: inherit;
                text-decoration: none;
                @extend .color-transition;

                &:hover { color: $mobile-menu-button-hover-color; }
                &:active { color: $mobile-menu-button-color; }
            }
        }

        .modal-photo {
            @extend .img-responsive;
            width: 100%;
        }
    }
}