$heightRatio: 4/7;

.video-en-tete {
    position: relative;
    width: 100%;
    height: 640px * $heightRatio;

    @media (min-width: $screen-sm-min) {
        height: $screen-sm-min * $heightRatio;
    }

    @media (min-width: $screen-md-min) {
        height: $screen-md-min * $heightRatio;
    }

    @media (min-width: $screen-lg-min) {
        height: $screen-lg-min * $heightRatio;
    }

    .caret-down {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate3d(-50%,0,0);
        font-size: 48px;
        color: $translucent-en-tete-grey;
    }
    

    .background-video-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; 
        overflow: hidden;

        video,
        iframe {
            width: 100%;
            height: 100%; 
            object-fit: cover;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            background-color: rgba(0, 0, 0, 0.5);

            h1 {
                @extend .en-tete-font-sizes;

                color: $en-tete-grey;
                font-weight: 600;

                .green {
                    color: $green;
                }
            }
        }
    }

    background-color: grey;
}

.accueil-video-section {
    @extend .main-font-sizes;
    color: $en-tete-grey;
    background-color: $index-video-grey;
    padding-top: 4em;
    padding-bottom: 4em;

    .main-title-row {
        margin-bottom: 4em;

        @media (min-width: $screen-lg-min) {
            margin-bottom: 6em;
        }

        h1 {
            margin-bottom: 0.5em;
            
            @media (min-width: $screen-lg-min) {
                font-size: 6rem;
            }
        }

        hr {
            width: 50%;
            border-top: 2px solid $en-tete-grey;
        }
    }

    .caption {
        margin-top: 0.5em;
        color: white;
    }
}