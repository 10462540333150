@mixin align-row($vertical-align) {
    display: table;
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    & > [class^=col] {
        display: table-cell;
        vertical-align: $vertical-align;
        float: none;

        &.visible-xs-cell {
            display: none !important;
            
            @media (max-width: $screen-xs-max) {
                display: table-cell !important;
            }
        }
    }
}

.row.align-top {
    @include align-row(top);
    margin-left: -15px;
    margin-right: -15px;
}

.row.align-top-sm {
    @media (min-width: $screen-sm-min) {
        @include align-row(top);
        margin-left: -15px;
        margin-right: -15px;
    }
}

.row.align-bottom {
    @include align-row(bottom);
}


.row.align-bottom-sm {
    @media (min-width: $screen-sm-min) {
        @include align-row(bottom);
    }
}