$translucid-black: rgba(0, 0, 0, 0.8);
$translucid-white: rgba(255, 255, 255, 0.8);
$green: rgb(159, 210, 6);
$dark-green: darken($green, 10%);

$lighter-grey: rgb(235,236,237);
$light-grey: rgb(172, 172, 172);
$medium-grey: #555555;
$main-grey: rgb(121,128,135);
$equipe-grey: #798087;
$index-video-grey: #252627;
$translucent-dark-grey: rgba(0,0,0,0.5);
$translucent-darker-grey: rgba(0,0,0,0.75);
$en-tete-grey: rgb(172,186,194);
$translucent-en-tete-grey: rgba(172,186,194,0.8);

$mobile-menu-button-color: $light-grey;
$mobile-menu-button-hover-color: white;
$mobile-menu-button-active-color: $green;

$mobile-menu-background-color: $translucid-black;
$mobile-menu-current-color: $green;
$mobile-menu-hover-color: $light-grey;
$mobile-menu-active-color: $green;

$header-contact-line-color: $green;
$header-contact-text-color: black;
$header-contact-text-hover-color: white;
$header-contact-text-active-color: $light-grey;

$footer-color: white;
$footer-email-phone-color: $green;

$main-color: $main-grey;

$form-background-color: $lighter-grey;
$datepicker-disabled-color: $lighter-grey;

$equipe-hover-color: transparentize($medium-grey, 0.5);

$member-modal-background-color: $translucent-dark-grey;

.fix-transition-nudging-bug {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.color-transition {
    transition: all 0.1s ease-in;
    @extend .fix-transition-nudging-bug;
}

.color-transition-long {
    transition: all 0.2s ease-in;
    @extend .fix-transition-nudging-bug;
}