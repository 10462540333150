.image-hover-container,
.video-hover-container {
    position: relative;
}

.image-hover-container {
    img {
        position: relative;
        width: 100%;
        vertical-align: top;
    }
}

.image-hover,
.video-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    background: $equipe-hover-color;
    @extend .color-transition-long;

    .hover-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @extend .main-text-font-sizes;
        color: $green;
        text-transform: uppercase;
        text-align: center;
        font-weight: 800;
        @extend .color-transition-long;

        .play-button {
            @extend .fa;
            @extend .fa-play;
            @extend .fa-3x;

            color: $green;
            border-radius: 50% 50%;
            border: 3px solid $green;
            padding: 0.25em 0.25em 0.25em 0.4em;
            margin-bottom: 0.25em;
            @extend .color-transition-long;
        }

        &:hover, &:focus {
            color: white;
            text-decoration: none;
            cursor: pointer;

            .play-button {
                border-color: white;
                color: white;
            }
        }
    }
}

.image-hover {
    opacity: 0;

    .hover-text {
        opacity: 0;
    }
}


.image-hover-container:hover,
.image-hover-container:focus {
    .image-hover {
        opacity: 1;

        .hover-text {
            opacity: 1;
        }
    }
}



