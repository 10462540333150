@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);

$font-family-base: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$mobile-menu-font-size: 1.6rem;

$desktop-menu-font-size: 1.05rem;
$desktop-menu-font-size-lg: 1.2rem;

$footer-font-size: 1.0rem;
$footer-font-size-sm: 1.2rem;
$footer-font-size-lg: 1.4rem;

$main-title-font-size: 4.8rem;
$main-title-font-size-lg: 7.2rem;

$main-text-font-size: 1.6rem;
$main-text-font-size-lg: 1.8rem;

$main-text-h1-font-size: 2.6rem;
$main-text-h1-font-size-lg: 3.0rem;

$form-font-size: 1.2rem;
$form-font-size-lg: 1.4rem;

$equipe-title-font-size: 2.4rem;
$equipe-font-size: 1.8rem;


.desktop-menu-font-size {
    font-size: $desktop-menu-font-size;

    @media (min-width: $screen-lg-min) {
        font-size: $desktop-menu-font-size-lg;
    }
}

.desktop-menu-line-height-2x {
    line-height: $desktop-menu-font-size * 2;

    @media (min-width: $screen-lg-min) {
        line-height: $desktop-menu-font-size-lg * 2;
    }
}

.desktop-menu-line-height-3x {
    line-height: ceil($desktop-menu-font-size / 1rem * 10px * 3);

    @media (min-width: $screen-lg-min) {
        line-height: $desktop-menu-font-size-lg * 3;
    }
}

.main-text-font-sizes {
    h1 {
        font-size: $main-text-h1-font-size;

        @media (min-width: $screen-lg-min) {
            font-size: $main-text-h1-font-size-lg;
        }
    } 

    p, li, address, table td {
        font-size: $main-text-font-size;

        @media (min-width: $screen-lg-min) {
            font-size: $main-text-font-size-lg;
        }
    } 
}

.en-tete-font-sizes {
    font-size: $main-text-h1-font-size;

    @media (min-width: $screen-sm-min) {
        font-size: $main-title-font-size;
    }

    @media (min-width: $screen-lg-min) {
        font-size: $main-title-font-size-lg;
    }
}

.main-font-sizes {
    .main-title-row h1 {
        font-size: $main-title-font-size;

        @media (min-width: $screen-lg-min) {
            font-size: $main-title-font-size-lg;
        }
    }

    .main-text {
        @extend .main-text-font-sizes;
    }
}

.form-font-sizes {
    font-size: $form-font-size;

    @media (min-width: $screen-lg-min) {
        font-size: $form-font-size-lg;
    }
}

.equipe-fonts {
    h2 {
        font-size: $equipe-title-font-size;
        font-weight: 800;
    }
    p {
        font-size: $equipe-font-size;
        font-weight: 600;
    }
}

.member-bio-fonts {
    font-size: $equipe-font-size;
    font-weight: 500;
}

.footer-font-size {
    font-size: $footer-font-size;

    @media (min-width: $screen-sm-min) {
        font-size: $footer-font-size-sm;
    }

    @media (min-width: $screen-lg-min) {
        font-size: $footer-font-size-lg;
    }
}

body {
    font-family: $font-family-base;
}

.normal {
    font-weight: 400;
}