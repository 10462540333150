@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";
@import "bootstrap-datetimepicker/bootstrap-datetimepicker-build";
@import "select2/core";
@import "fullpage/jquery.fullPage";
@import "fonts";
@import "colors";
@import "dimensions";
@import "grid";
@import "footer";
@import "forms";
@import "background-image";
@import "image-hover";
@import "modal";
@import "photo-slider";
@import "traitements-capsules";
@import "index";

html {
    position: relative;
    min-height: 100%;
    background-color: black;
}

body {
    margin: 0;
    height: 100%;
}

main {
    min-height: 500px;
    width: 100%;
}

.main-container {
    @extend .container;
    background-color: $translucid-white;
    color: $main-color;
    padding-top: 10rem;
    padding-bottom: 10rem;

    @extend .main-font-sizes;

    hr {
        width: 33%;
        border-top: 2px solid $main-color;
    }
}

.traitements-container,
.capsules-container {
    @extend .main-container;
    padding-bottom: 0;
}

.equipe-title-container {
    @extend .main-container;
    padding-bottom: 3em;
}

.equipe-section {
    background-color: white;
    color: $equipe-grey;
    padding-top: 2em;
    padding-bottom: 4em;

    @extend .equipe-fonts;

    .member-col {
        margin-top: 1em;
        margin-bottom: 1em;

        .member {
            display: inline-block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;

            .member-image {
                @extend .image-hover-container;
            }

            .member-titles {
                padding-left: 2em;
                padding-right: 2em;
            }
        }

        
    }
}

.main-title-row {
    @extend .row;
    margin-bottom: 2em;

    h1 {
        font-weight: 300;
        text-transform: uppercase;
    }
}

.main-text {
    h1 {
        font-weight: 600;
        color: $green;
        text-transform: uppercase;
        @extend .mt2;
    }

    p, address {
        line-height: 1.6em;
    }

    p {
        @extend .text-justify;
    }

    a {
        color: $dark-green;

        &:hover, &:focus {
            color: $green;
            text-decoration: none;
        }
    }
}

.bootstrap-datetimepicker-widget table {
    td.disabled,
    td.disabled:hover,
    td span.disabled,
    td span.disabled:hover,
    th.disabled,
    th.disabled:hover {
        color: $datepicker-disabled-color;
    }
}

.horaire {
    width: 100%;

    td {
        padding-bottom: 0.667em;
        vertical-align: bottom;
    }
}



