.traitements-section,
.capsules-section {
    @extend .main-text-font-sizes;

    h1 {
        font-weight: 300;
        text-transform: uppercase;
    }

    ul {
        list-style-type: none;
        font-weight: 600;
        padding-right: 2rem;
        line-height: 1.5em;

        li {
            padding-bottom: 1.25em;
        }
    }

    .specialites,
    .videos {
        padding-bottom: 2em;
        
        @media (min-width: $screen-sm-min) {
            margin-left: 2em;
        }

        hr {
            width: 100%;
        }
    }

    .specialites-col {
        background-color: $green;

        @media (min-width: $screen-sm-min) {
            padding-right: 0;
        }

        .specialites {
            @media (min-width: $screen-sm-min) {
                padding-right: 0;
            }
            color: white;

            ul {
                padding-left: 0;
            }

            h1 {
                color: $main-color;
            }
        }
    }

    .videos-col {
        .video-col {
            padding-bottom: 2em;
        }

        @media (max-width: $screen-xs-max) {
            h1 {
                margin-top: 1.5em;
            }
        }
    }

    
}