$header-height-xs: 176px;
$header-height-sm: 126px;
$header-height-md: 123px;
$header-height-lg: 135px;

header {
    background-color: black;
    text-transform: uppercase;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    height: $header-height-xs;

    @media (min-width: $screen-sm-min) {
        height: $header-height-sm;
    }
    @media (min-width: $screen-md-min) {
        height: $header-height-md;
    }
    @media (min-width: $screen-lg-min) {
        height: $header-height-lg;
    }
}

.under-header
{
    margin-top: $header-height-xs;

    @media (min-width: $screen-sm-min) {
        margin-top: $header-height-sm;
    }
    @media (min-width: $screen-md-min) {
        margin-top: $header-height-md;
    }
    @media (min-width: $screen-lg-min) {
        margin-top: $header-height-lg;
    }
}

main {
    @extend .under-header;
}

.header-container {
    @extend .pt1;
    @extend .pb1;
}

.header-container .row {
    @media (min-width: $screen-md-min) {
        padding-bottom: $desktop-menu-font-size;
    }
}

.contour-btn {
    border: 2px solid $header-contact-text-color;
    padding: 0.16667em 0.5em;
    strong {
        color: $header-contact-text-color;
    }

    &:hover {
        background-color: $header-contact-text-color;
        &, & strong {
            color: $header-contact-text-hover-color;
            @extend .color-transition;
        }
    }
    &:active {
        &, & strong {
            color: $header-contact-text-active-color;
        }
    }
}

.contact-line, .desktop-menu {
    @extend .desktop-menu-font-size;
    @extend .desktop-menu-line-height-3x;
}

.contact-line {
    position: relative;
    display: inline-block;
    z-index: 5;
    transition: height 0.1;

    margin-top: $desktop-menu-font-size * 2;
    margin-bottom: $desktop-menu-font-size * 2;

    width: 100%;

    @media (min-width: $screen-sm-min) {
        margin-bottom: $desktop-menu-font-size * 1.5;
        width: 40rem;
    }

    @media (min-width: $screen-md-min) {
        margin-bottom: 0;
        width: 63.3rem;
    }

    @media (min-width: $screen-lg-min) {
        margin-top: $desktop-menu-font-size-lg * 2;
        width: 73.8rem;
    }

    background-color: $header-contact-line-color;
    color: $header-contact-text-color;

    &:before {
        position: absolute;

        left: -($desktop-menu-font-size * 1.8);
        width: $desktop-menu-font-size * 2;
        height: $desktop-menu-font-size * 3;
        transition: height 0.1;

        @media (min-width: $screen-lg-min) {
            left: -($desktop-menu-font-size-lg * 1.8);
            width: $desktop-menu-font-size-lg * 2;
            height: $desktop-menu-font-size-lg * 3;
        }

        content: "";
        background-image: url('../img/angle-vert.png');
        background-repeat: no-repeat;
        background-position: center left;
        background-size: contain;
    }

    a {
        color: inherit;
        text-decoration: none;
        @extend .color-transition;

        &:hover { color: $mobile-menu-button-hover-color; }
        &:active { color: $mobile-menu-button-color; }
    }

    .contour-btn {
        margin-left: 0.1em;
    }

    .fa {
        position: relative;
        top: $desktop-menu-font-size / 4;
        margin: 0 0 0 $desktop-menu-font-size;
        @media (min-width: $screen-lg-min) {
            top: $desktop-menu-font-size-lg / 4;
            margin: 0 0 0 $desktop-menu-font-size-lg;
        }
    }
}

.outside-contact-line {
    position: absolute;
    top: $desktop-menu-font-size * 2 + 1.4rem;
    right: 0;
    transition: height 0.1;

    width: 50%;
    height: $desktop-menu-font-size * 3;
    @media (min-width: $screen-lg-min) {
        top: $desktop-menu-font-size-lg * 2 + 1.4rem;
        height: $desktop-menu-font-size-lg * 3;
    }

    z-index: 1;
    background-color: $green;
}

.social-icons {
    position: absolute;
    display: inline-block;
    right: 30px;

    @media (min-width: $screen-sm-min) {
        right: 15px;
    }
}


.mobile-menu-button {
    color: $mobile-menu-button-color;
    @extend .color-transition;

    &:hover, &:focus    { color: $mobile-menu-button-hover-color; }
    &:active            { color: $mobile-menu-button-active-color; }
}

.mobile-menu, .desktop-menu {
    font-weight: bold;
    color: white;

    ul {
        list-style: none;
        margin: $mobile-menu-font-size 0;
        padding-left: 0;

        li {
            a {
                color: inherit;
                text-decoration: none;

                &.disabled,
                &.disabled:hover,
                &.disabled:focus,
                &.disabled:active { color: $mobile-menu-button-color; }
            }

            &.current {
                color: $mobile-menu-current-color;
                @extend .color-transition;
            }

            &:hover, &:focus {
                color: $mobile-menu-hover-color;
                @extend .color-transition;
            }
            
            &:active {
                color: $mobile-menu-active-color;
                @extend .color-transition;
            }


        }
    }
}

.mobile-menu {
    display: none;
    position: fixed;
    width: 100%;
    z-index: 50;

    text-align: center;
    background-color: $mobile-menu-background-color;
    
    font-size: $mobile-menu-font-size;
    line-height: $mobile-menu-font-size * 2;
    text-transform: uppercase;
}

.desktop-menu ul {
    margin-bottom: 0;
    margin-top: $desktop-menu-font-size * 2;
    line-height: $desktop-menu-font-size;
    
    @media (min-width: $screen-lg-min) {
        margin-top: $desktop-menu-font-size-lg * 2;
        line-height: $desktop-menu-font-size-lg;
    }

    li {
        display: inline-block;
        padding-left: 1.5em;
    }
}