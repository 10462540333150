footer {
    background-color: black;
    text-transform: uppercase;
    @extend .footer-font-size;
    font-weight: 600;
    color: $footer-color;

    h1 {
        @extend .footer-font-size;
        font-weight: 700;
        color: white;
        @extend .mb2;
    }

    address a,
    address a:hover,
    address a:focus,
    address a:active {
        color: $footer-email-phone-color;
        text-decoration: none;
    }

    address.email-phone {
        color: $footer-email-phone-color;

        .phone {
            font-size: 1.3em;
        }
    }
}

.footer-container {
    @extend .pt2;
    @extend .pb4;
}


