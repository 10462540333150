.background-image {
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

main#equipe,
main#philosophie,
main#traitements,
main#contact,
main#photos,
main#rendez_vous,
main#nouveau_patient,
{
    @extend .background-image;
}

main#equipe {
    background-image: url('../img/equipe.jpg');
}

main#philosophie {
    background-position: 0 center;
}

main#rendez_vous,
main#nouveau_patient,
{
    background-image: url('../uploads/img/photo_1.jpg');
}